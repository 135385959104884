.portfolio__projet-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: var(--font-family3);
  margin-top: 6rem;
}

.portfolio__projet-container-text {
  color: #523e50;
  text-align: center;
  margin-bottom: 6rem;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.portfolio__projet-container-text h1 {
  font-size: 40px;
  font-weight: bold;
  margin-bottom: 1rem;
  margin-top: 4rem;
}
.portfolio__projet-container-text p {
  line-height: 25px;
  width: 75%;
  text-align: center;

}

.portfolio__projet-container-projets {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  width: 100%;
}

.portfolio__projet-container-minprojets {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 2rem;
  flex-wrap: wrap;

}

.portfolio__projet-container-info {
  margin-top: 1rem;
  margin-right: 3rem;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}
.portfolio__projet-container-info h4 {
  font-family: var(--font-family3);
  font-weight: lighter;
  color: #523e50;
  margin-left: 0.2rem;

}
.portfolio__projet-container-info img {
width: 25px;
}



/* ----------------------------- Responsive  */
@media screen and (max-width: 1410px) {
  .portfolio__projet-container-minprojets {
    flex-wrap: wrap;
    justify-content: space-around;
  }
}

@media screen and (max-width: 883px) {
  .portfolio__projet-container-minprojets {
    flex-wrap: wrap;
    justify-content: space-around;
  }
  .portfolio__projet-container-minprojets {
    margin-top: 1rem;
  }
}

@media screen and (max-width: 530px) {
  .portfolio__projet-container {
    margin-top: 4rem;
  }
  .portfolio__projet-container-text {
    margin-bottom: 4rem;
  }
  .portfolio__projet-container-info {
    margin-right: 0;
    justify-content: center;
  }
}