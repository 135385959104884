.product {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 40%;
  align-items: center;
  padding-bottom: 2rem;
}

.portfolio__product {
  width: 100%;
  height: 260px;
  margin: 1rem 1rem;
  box-shadow: 10px 10px 208px -4px #c2cdff;
  -webkit-box-shadow: 10px 10px 208px -4px #c2cdff;
  -moz-box-shadow: 10px 10px 208px -4px #c2cdff;
  border-radius: 15px 15px 0 0;
  overflow: hidden;
  text-align: center;
  border: 0.1px solid #b7b7b7;
  background: rgb(243, 242, 242);
  flex-wrap: wrap;


}

.portfolio__product-browser {
  height: 20px;
  background-color: rgb(243, 242, 242);
  display: flex;
  align-items: center;
  position: sticky;
  z-index: 2;
}

.portfolio__product-browser-circle {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin: 0.2rem;
}

.portfolio__product-browser-circle.red {
  background-color: #f25656;
  margin-left: 0.8rem;
}
.portfolio__product-browser-circle.black {
  background-color: #515050;
}
.portfolio__product-browser-circle.green {
  background-color: #33cf33;
}

.circle__left {
  margin-left: 1rem;
}

.portfolio__product-image img {
  width: 100%;
  transition: all 10s ease;
  cursor: pointer;
}

.portfolio__product-image img:hover {
  transform: translateY(-70%);
}

@media screen and (max-width: 750px) {
  .product {
    width: 100%;
    padding-bottom: 1rem;

  }

  .portfolio__product {
    height: 400px;
    margin: 2rem 0rem;
  }
  .portfolio__product-browser-circle {
    width: 5px;
    height: 5px;
    border-radius: 50%;
    margin: 0.1rem;
  }
  .portfolio__product-button {
      margin-bottom: 2rem;
  }

  .portfolio__product-image img:hover {
    transform: translateY(0);
  }

}
@media screen and (max-width: 530px) {
  .portfolio__product {
    width: 100%;
    height: 300px;
  }
}
