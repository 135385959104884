.portfolio__popup {
    width: 100%;
    height: 100%;
    position: fixed;
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
    z-index: 1000000000;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid gray;
}

.portfolio__popup-container {
    position: absolute;
    z-index: 200000000000000;
    width: 600px;
    height: auto;
    /* max-height: 85vh; */
    background-color: white;
    border-radius: 20px;
    overflow: hidden;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

.portfolio-container-img img {
    width: 100%;
    height: 100%;
}

.portfolio__popup-browser {
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    align-items: center;
}


.modal__open-block {
    overflow: hidden;
    position: fixed;
}

.portfolio__popup-container-close {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: rgb(243, 242, 242);
    align-items: center;
    align-content: center;
}

.portfolio__popup-container-closebutton {
    cursor: pointer;
    margin: 0rem 1rem;
    font-size: 34px;
    padding: .4rem 0.6rem;
}

.portfolio-container-text {
    padding: 1rem 1.5rem;
}
.portfolio-container-text h1 {
    margin-top: 0.5rem;
    margin-bottom: 1.5rem;
    font-family: var(--font-family3);
    color: #523e50;
}
.portfolio-container-text p {
    margin-bottom: 2.5rem;
    font-family: var(--font-family3);
    color: #523e50;
    line-height: 25px;
    text-align: justify;


}



.pointer {
    cursor: pointer;
}



@media screen and (max-width: 630px) {
    .portfolio__popup-container {
        width: 85%;
    }
  }
@media screen and (max-height: 750px) {
    .portfolio__popup-container {

        /* width: 600px; */
        /* height: 700px; */
        max-height: auto;

    }
  }
@media screen and (max-height: 723px) {
    .portfolio__popup-container {
        max-height: auto;

    }
  }



