* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
}

body {
  background: white;
}

a {
  color: unset;
  text-decoration: none;
}

.gradient__bg {
  /* background-image: linear-gradient(180deg, #aeffe8 0%, #c2cdff 100%); */
  /* background-color: #cdfdef; */
/* background-image: linear-gradient(180deg, #cdfdef 0%, #dde4ff 70%); */
background-color: #e0fff4;
background-image: linear-gradient(180deg, #e0fff4 0%, #ebefff 70%);

  /* background: #F0F0F0; */

}

.gradient__text {
  display: block;
  text-shadow: 0px 0px #00000000;
  background: var(--gradient-text);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.section__padding {
  padding: 3rem 6rem 3rem 6rem;
}

.section__margin {
  margin: 3rem 6rem 3rem 6rem;
}

@media screen and (max-width: 700px) {
  .section__padding {
    padding: 4rem;
  }

  .section__margin {
    margin: 4rem;
  }
}

@media screen and (max-width: 550px) {
  .section__padding {
    padding: 4rem 1.5rem;
  }

  

  .section__margin {
    margin: 4rem .2rem;
  }
}
