.hook__footer {
  background-color: #031b34;
  font-family: var(--font-family);
  z-index: 100000;
  position: relative;
}

.hook__container-text {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.hook__container-text h1 {
  margin-top: 2rem;
  margin-bottom: 2rem;
  text-align: center;
  font-weight: 800;
  font-size: 36px;
  line-height: 45px;
}
.hook__footer_button {
  padding: 1.1em 3em;
  border: 2px solid white;
  position: relative;
  overflow: hidden;
  background-color: transparent;
  text-align: center;
  text-transform: uppercase;
  font-size: 12px;
  color: #ddd;
  transition: 0.3s;
  z-index: 1;
  color: white;
  cursor: pointer;
  margin-bottom: 2rem;
  font-family: var(--font-family);
}

.hook__footer_button::before {
  content: "";
  width: 0;
  height: 300%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(45deg);
  background: white;
  transition: 0.5s ease;
  display: block;
  z-index: -1;
}

.hook__footer_button:hover::before {
  width: 105%;
}

.hook__footer_button:hover {
  color: #111;
}

.hook__footer_button:active {
  transform: scale(0.9);
}

/* -----------------Footer link ------------- */
.hook__container-details {
  color: white;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 4rem;
  margin-bottom: 2rem;
}

.hook__container-details-1 {
  margin-right: 2rem;
}
.hook__container-details-2 {
  margin-right: 2rem;
}
.hook__container-details-3 {
  margin-right: 2rem;
}

.hook__container-details-1 img {
  margin-bottom: 1rem;
}

.hook__container-details h4 {
  margin-bottom: 1rem;
}
.hook__container-details p {
  margin-bottom: 1rem;
}

.hook__container-details p {
  font-size: 12px;
  line-height: 20px;
}

.hook__container-right {
  margin-top: 2rem;
  margin-bottom: 1rem;
  color: white;
  text-align: center;
}

.div {
  width: 38px;
  height: 1px;
  background: var(--gradient-bar);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  margin-bottom: 0.25rem;
  text-align: center;
  width: 100%;
}

@media screen and (max-width: 550px) {
  .hook__container-details {
    flex-direction: column;
    text-align: center;
  }
  .hook__container-details-1 {
    margin-right: 0;
    margin-bottom: 1.3rem;
  }
  .hook__container-details-2 {
    margin-right: 0;
    margin-bottom: 1.3rem;
  }
  .hook__container-details-3 {
    margin-right: 0;
    margin-bottom: 1.3rem;
  }

  .hook__container-details h4 {
    margin-bottom: 0.5rem;
  }
  .hook__container-details p {
    margin-bottom: 0.3rem;
  }
}
