@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Pushster&family=Righteous&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;400;500;600;700;900&display=swap');

:root {
  --font-family: "Manrope", sans-serif;
  --font-family1: "Pushster", cursive;
  --font-family2: "Righteous", cursive;
  --font-family3: 'Montserrat', sans-serif;

  --gradient-text: linear-gradient(89.97deg, #e0fff4 1.84%, #f49867 102.67%);
  --gradient-bar: linear-gradient(103.22deg, #ae67fa -13.86%, #f49867 99.55%);

  --color-bg: ;
  --color-footer: #031b34;
  --color-blog: #042c54;
  --color-text: #81afdd;
  --color-subtext: #ff8a71;
}
